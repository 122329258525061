<template>
    <div v-if="isLoggedIn" class="container page pgGtk">
        <h4>{{ title }}</h4>
        <div class="form-group row mt-5">
            <label class="col-lg-1 col-form-label d-none" for="country">Cluster:</label>
            <div class="col-lg-5 d-none"><b-form-select v-model="selectedCountryCode" :options="countryList" value-field="countryCode" text-field="country" v-on:change="resetDivision($event)"></b-form-select></div>
            <label class="col-lg-1 col-form-label" for="division">Division:</label>
            <div class="col-lg-5"><b-form-select v-model="selectedDivisionCode" :options="divisionList" value-field="divisionCode" text-field="division"></b-form-select></div>
        </div>
        <div class="form-group row">
            <label class="col-lg-1 col-form-label" for="txtSearch">Search:</label>
            <div class="col-sm-4" style="padding-bottom:5px"><b-form-input name="txtSearch" v-model="search" placeholder="" maxlength="20" /></div>
            <div class="col-sm-1"><input type="button" id="searchButton" value="Search" class="btn btn-primary" v-on:click="onSearchButtonClick" /> </div>
        </div>

        <div class="row">
                <div v-if="currentMemberList.length===0"><i>No records available</i></div>
                <div v-for="r in currentMemberList" :key="r._rowNum" class="col-sm-4 p-0">
                    <div class="p-2">
                        <div class="container-fluid" style="min-height:290px; outline:solid 1px #ccc;">
                            <div class="row">
                                <div class="col-sm-5">
                                    <div class="gtkPic"><img class="w-100" :src="('/profilepic/' + r.picId)" /></div>
                                    <div>&nbsp;</div>
                                    <div class="text-center">
                                        <!--<router-link :to="`/nominate/${r.memberId}`" class="btn btn-primary">Nominate</router-link>-->
                                        <router-link :to="`/nominate`" class="btn btn-primary">Nominate</router-link>
                                    </div>
                                </div>
                                <div class="col-sm-7" style="padding-top:5px;">
                                    <div class="gtkName">{{r.memberName}}</div>
                                    <div class="gtkPos">{{r.natureOfBusiness}}</div>
                                    <div class="gtkCompany" title="">{{r.division}}</div>
                                    <div class="gtkHead" v-if="r.dOB">Birthday</div>
                                    <div class="gtkData">{{r.dOB}}</div>
                                    <div class="gtkHead" v-if="r.interestOtherDesc">Hobbies &amp; Interests</div>
                                    <div class="gtkData">{{r.interestOtherDesc}}</div>
                                    <div class="gtkHead" v-if="r.otherInfo">Dreams and Aspirations</div>
                                    <div class="gtkData">{{r.otherInfo}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>

        <!--<div class="scrollBox" style="padding: 15px 0; border-top: #eee solid 1px; border-bottom: #eee solid 1px;">
        <div v-if="selectedDivisionCode==null && search==''"><i>Please select a division or search all divisions</i></div>
        <template v-else>
            <div class="row" style="">
                <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                <div class="col-11">
                    <a v-if="isAllowWholeDivision" href="#" v-on:click.prevent="onAddRecipient(-1)" v-b-tooltip.rightbottom.hover title="Add entire division" style="color:#9f34a0">Entire division: {{ this.selectedDivisionName }}</a>
                    <span v-else style="color:#9f34a0">{{ this.selectedDivisionName }}</span>
                </div>
            </div>

            <div v-for="r in memberList" :key="r._rowNum" class="row">
                <template v-if="r.memberId.substring(0, 2) === '0.'">
                    <div class="col-1 text-right"><b-icon icon="exclamation-circle" style="color:#efe700"></b-icon></div>
                    <div class="col"><span style="color:red" v-b-tooltip.rightbottom.hover.v-danger title="This user has not registered">{{ r.firstname + ' ' + r.surname }}</span></div>
                    <div class="col" style="color:red">{{ r.division }}</div>
                </template>
                <template v-else>
                    <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                    <div class="col"><a href="#" v-on:click.prevent="onAddRecipient(r._rowNum)">{{ r.firstname + ' ' + r.surname }}</a></div>
                    <div class="col">{{ r.division }}</div>
                </template>
            </div>
        </template>
    </div>-->

    </div>
</template>

<script>

    import sjData from '@/sjUtilData'
    import axios from 'axios'
    import { sjDebounce } from '@/sjDebounce'


    export default {
        name: 'GetToKnow',
        components: {
        },
        data() {
            return {
                doLoad: false,
                selectedCountryCode: null,
                selectedDivisionCode: null,
                cachedMemberList: {
                    //sanlamcorporate: [...],
                    //safrican: [...]
                },
                currentMemberList: [],
                search: '',

                selectedMembersSerialNo: 0,

                defaultCountryCode: 'ZA',
                defaultDivisionCode: '',

                lastCountryCode: null,
                lastDivisionCode: null,
                lastSearch: null,

            }
        },
        props: {
            title: {
                type: String, required: false, default: 'GET TO KNOW'
            },
            selectedTitle: {
                type: String, required: false, default: 'Selected recipients'
            },
            includeSelf: {
                type: Boolean, required: false, default: false
            },
            //defaultCountryCode: {
            //    type: String, required: false, default: this.memberClusterCode
            //},
            //defaultDivisionCode: {
            //    type: String, required: false, default: this.memberDivisionCode
            //},
            isCountryFixed: {
                type: Boolean, required: false, default: false
            },
            isAllowWholeDivision: {
                type: Boolean, required: false, default: true
            },
            isSingle: {
                type: Boolean, required: false, default: false
            },
            showContinue: {
                type: Boolean, required: false, default: true
            },

        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            isDivisionsLoaded() {
                return this.$store.state.isDivisionsLoaded
            },
            //defaultCountryCode() {
            //    return this.$store.state.memberStore.member.clusterCode
            //},
            //defaultDivisionCode() {
            //    return this.$store.state.memberStore.member.divisionCode
            //},

            alltext() {
                return this.title + "..." + this.msg;
            },

            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },

            divisionList() {
                return [{ divisionCode: null, division: '-- Please select an Division--', disabled: false }]
                    .concat(this.$store.state.divisionList.filter(division => division.countryCode === this.selectedCountryCode));
            },

            selectedDivisionName() {
                if (typeof this === undefined) return '';
                return this.selectedDivisionCode == null ? '' : this.$store.state.divisionList.filter(division => division.divisionCode === this.selectedDivisionCode)[0].division;
            }
        },

        watch: {
            doLoad(isLoaded) {
                if (isLoaded) {
                    this.doGetMemberData(this.selectedCountryCode, this.selectedDivisionCode);
                }
            },
            isLoggedIn(isLoggedIn) {
                if (isLoggedIn) {
                    let defaultCountryCode = this.$store.state.memberStore.member.clusterCode;
                    let defaultDivisionCode = this.$store.state.memberStore.member.divisionCode;
                    this.selectedCountryCode = defaultCountryCode;
                    this.selectedDivisionCode = defaultDivisionCode;
                    //this.defaultCountryCode = defaultCountryCode;
                    //this.defaultDivisionCode = defaultDivisionCode;

                    console.log('Logged in...' + defaultCountryCode + ' ' + defaultDivisionCode);
                    this.doLoad = true;
                }
            },
            selectedDivisionCode(divisionCode) {  //newDivisionCode, oldDivisionCode - called when divisionCode changes
                if (typeof divisionCode === 'undefined') return;
                console.log('division changed to...' + divisionCode);

                this.search = ''; //divisionCode changed, so reset search
                this.doLoad = true;

            },
            selectedCountryCode(countryCode) {  //newDivisionCode, oldDivisionCode - called when divisionCode changes
                if (typeof countryCode === 'undefined') return;

                console.log('country changed to...' + countryCode);
                this.search = '';
                this.doLoad = true;

            },
            search: sjDebounce(function () {
                console.log('search...');
                this.doLoad = true;
                //this.doGetMemberData(null);
            }, 800)
        },
        methods: {
            setDoLoad() {
                this.doLoad = true;
			},
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            resetDivision() {
                this.selectedDivisionCode = null;
            },
            doGetMemberData(countryCode, divisionCode) { //this is called from divsionCode changed, and search changed

                let search = this.search.trim();
                if (this.lastCountryCode === countryCode && this.lastDivisionCode === divisionCode && this.lastSearch === search) return;

                this.currentMemberList = [];
                console.log('getting country...' + countryCode + ' ' + divisionCode);

                if (countryCode === null) {
                    countryCode = 'ZA';
                }


                if (search.length !== 0 || divisionCode === null) {
                    divisionCode = '';

                }


                let axiosParams = { countryCode: countryCode, divisionCode: divisionCode, search: this.search };
                
                axios.post('/api/site/gettoknowlist', axiosParams)
                    .then(({ data }) => {
                        this.doLoad = false;
                        this.currentMemberList = new sjData(data.memberList).data;
                    });
            },

            onSearchButtonClick() {
                if (this.selectedDivisionCode == null) {
                    this.doGetMemberData(this.countryCode, ''); // No division is selected, so perform a search on all divisions
                }
            },


        },
        created() {
            console.log('*created');
        },
        mounted() {
            console.log('*mounted');
            this.$store
                .dispatch('getDivisionList', {}) //To retrieve Country and Division lists
                .then(() => {
                    this.displayPart = 0;

                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error)));
                });



        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
				console.log('*beforeRouteEnter');
				// access to component instance via `vm`

				let defaultCountryCode = vm.$store.state.memberStore.member.clusterCode;
				let defaultDivisionCode = vm.$store.state.memberStore.member.divisionCode;
				vm.selectedCountryCode = defaultCountryCode;
				vm.selectedDivisionCode = defaultDivisionCode;

				console.log('route enter.....' + defaultCountryCode + ' ' + defaultDivisionCode);

				vm.doLoad = true;
			})
		},
		beforeRouteUpdate(to, from, next) {
            console.log('*beforeRouteUpdate');
			let defaultCountryCode = this.$store.state.memberStore.member.clusterCode;
			let defaultDivisionCode = this.$store.state.memberStore.member.divisionCode;
			this.selectedCountryCode = defaultCountryCode;
			this.selectedDivisionCode = defaultDivisionCode;
			//this.defaultCountryCode = defaultCountryCode;
			//this.defaultDivisionCode = defaultDivisionCode;

            console.log('route update.....' + defaultCountryCode + ' ' + defaultDivisionCode);

            // just use `this`
            this.doLoad = true;
            next();
		}
    }
</script>

